import React, { memo } from "react"
import * as THREE from "three"
import { Canvas, extend, useLoader } from "@react-three/fiber"
import {
  Effects,
  OrbitControls,
  OrthographicCamera,
  Sparkles,
  Cloud,
  Edges,
} from "@react-three/drei"
import { UnrealBloomPass } from "three-stdlib"
import logo from "../../static/logo.png"
import cloud from "../../static/cloud.png"

extend({ UnrealBloomPass })

const CanvasAnimation = memo(() => {
  const repeatX = 1
  const repeatY = 1
  const base = useLoader(THREE.TextureLoader, logo)
  base.wrapS = THREE.RepeatWrapping
  base.wrapT = THREE.RepeatWrapping
  base.repeat.set(repeatX, repeatY)

  return (
    <Canvas shadow="true" gl={{ antialias: false }}>
      <color attach="background" args={["#202030"]} />
      <mesh scale={2}>
        <boxGeometry />
        <meshPhysicalMaterial map={base} />
        <Edges scale={1.2} threshold={2} color="#ffffff" />
      </mesh>
      <directionalLight intensity={1} position={[-10, -10, -10]} />
      <OrbitControls autoRotate enableZoom={false} />
      <OrthographicCamera
        makeDefault
        far={500}
        near={0.1}
        position={[-10, 10, -10]}
        zoom={100}
      />
      <hemisphereLight intensity={1} color="#2fed1c" groundColor="#2fed1c" />

      <Sparkles count={1000} scale={[20, 20, 10]} size={1.5} speed={0.01} />
      <fog attach={"fog"} args={["#202030", 5, 25]} />

      <Cloud
        opacity={0.1}
        speed={1}
        width={20}
        depth={0.1}
        segments={10}
        position={3}
        texture={cloud}
      />

      <Cloud
        opacity={0.1}
        speed={1}
        width={5}
        depth={0.1}
        segments={5}
        position={-3}
        texture={cloud}
      />

      <Effects disableGamma>
        <unrealBloomPass threshold={1} strength={2.0} radius={0.5} />
      </Effects>
    </Canvas>
  )
})

export default CanvasAnimation
