import React, { memo } from "react"
import "./styles.scss"

const Loading = memo(() => {
  return (
    <div className="wrapper">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  )
})

export default Loading
