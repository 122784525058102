import React, { useState, useEffect, memo, Suspense } from "react"
import CanvasAnimation from "../components/CanvasAnimation"
import Seo from "../components/Seo"
import Loading from "../components/Loading"

const IndexPage = memo(() => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return isMounted ? (
    <main>
      <Suspense fallback={<Loading />}>
        <CanvasAnimation />
      </Suspense>
      <div className="header">
        <h1>Hello world</h1>
        <a href="https://www.sontruong.dev" target="_blank" rel="noreferrer">
          <h2>{`</> Click here to see more_ </>`}</h2>
        </a>
      </div>
    </main>
  ) : null
})

export const Head = () => {
  return <Seo title="Demo App" />
}

export default IndexPage
